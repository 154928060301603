import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/": [~4],
		"/(app)/dashboard": [~5,[2]],
		"/(app)/dashboard/[topic]": [~6,[2]],
		"/playground": [~7,[3]],
		"/playground/options-api": [8,[3]],
		"/playground/options-api/(pages)/brokerage-account": [11,[3]],
		"/playground/options-api/(pages)/brokerage": [10,[3]],
		"/playground/options-api/(pages)/broker": [9,[3]],
		"/playground/options-api/(pages)/cash-or-nothing-option": [12,[3]],
		"/playground/options-api/deploy": [13,[3]],
		"/playground/streams-api": [14,[3]],
		"/playground/streams-api/(pages)/data-stream": [15,[3]],
		"/playground/streams-api/deploy": [19,[3]],
		"/playground/streams-api/(pages)/session": [16,[3]],
		"/playground/streams-api/(pages)/simple-subscriber": [17,[3]],
		"/playground/streams-api/(pages)/subscription-batch": [18,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';